.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-image: url('./images/IMG_0304.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-size: 1.3rem;
  color: white;
}

.App::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  z-index: 1;
  background: #000000;
  opacity: .5;
}

.Form {
  display: flex;
  flex-direction: column;
  text-align: left;
  z-index: 2;
}

.App-link {
  color: #61dafb;
}

.Form-label {
  margin-bottom: 10px;
}

.Form-input, .Form-btn {
  width: 260px;
  padding: 13px 10px;
  border: 0;
  border-radius: 7px;
  font-size: 1rem;
}

.Form-btn {
  width: 280px;
  background: #04b404;
  color: #fff;
  transition: all .3s ease-in-out;
  cursor: pointer;
}

a {
  text-align: center;
  text-decoration: none;
}

.Form-btn:hover {
  background: #059a05;
}